.cta {

    .form-input {
        border-color: transparent;
        border-radius: 2px;
    }
}

.cta-inner {
	padding-left: 16px;
	padding-right: 16px;
    background-color: get-color(primary, 3);  
}

@include media( '>medium' ) {

.cta {

    .form-input {
        min-width: 280px;
    }
}    

	.cta-inner {
		padding-left: 48px;
		padding-right: 48px;
	}
}
